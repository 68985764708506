export const constantVars = {
  VUE_MOUNT_POINT: '#searchResult',
  CLOUDSEARCH_SITE: 'ccrrc_us_en',
  SEARCH_URL: '/api/search',
  COUNCIL_REPLACE: 'Council - ',
  COUNCIL_ALL_SEARCH: 'Council',
  QUERY_PARAM: 'q',
  FEATURED_SEARCH_TERM: 'Featured',
  FEATURED_REPORTS: 'Reports'
};

export const vuePages = {
  FEATURED: 'Featured',
  COUNCIL: 'Council',
  REPORTS: 'Reports',
  BLOG: 'Blog',
  NEWS: 'News',
  SEARCH: 'Search'
};

export const categories = [
  'Shoppers',
  'Insights',
  'Sustainability',
  'Technology',
  'Management',
  'Employees'
];

export const councils = [
  'Asia Pacific',
  'Eurasia & Africa',
  'Europe',
  'Latin America',
  'North America Large Store',
  'North America NACS'
];
<template>
  <div class="article-date-container">
    {{ getArticleDate }}
  </div>
</template>

<script>
import { constantVars } from '../../../utils/constants';

export default {
  name: 'ArticleDate',
  props: {
    publicationDate: {
      type: String,
      required: true
    },
    contentType: {
      type: String,
      required: true
    },
    showCouncil: {
      type: Boolean,
      required: true
    }
  },
  computed: {
    getFullDate () {
      const articleDate = new Date(this.publicationDate);
      const month = articleDate.toLocaleString('en-us', { month:'long'});
      return `${month} ${articleDate.getDate()}, ${articleDate.getFullYear()}`
    },
    getCouncilAndDate () {
      const articleDate = new Date(this.publicationDate);
      const council = this.contentType.replaceAll(constantVars.COUNCIL_REPLACE, '');
      return `${council}, ${articleDate.getFullYear()}`
    },
    getArticleDate () {
      return this.showCouncil ? this.getCouncilAndDate : this.getFullDate;
    }
  }
}
</script>

<style lang="scss" scoped>

.article-date-container {
  margin: 0 0 10px 0;
  color: #757f87;
  font-weight: bold;
  font-size: 16px;
  font-family: Open Sans Bold, Arial;
}

</style>
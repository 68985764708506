<template>
  <ul 
    v-show="showPagination"
    class="pagination"
    >
    <li class="pagination-item">
      <button
        type="button"
        @click="onChangePage(currentPage - 1)"
        :disabled="isInFirstPage"
        data-cy-pagination="down"
      >
        &lt;&lt;
      </button>
    </li>
    <li class="pagination-item">
      <button
        type="button"
        @click="onChangePage(startPage)"
        :class="{ 'active': isInFirstPage }"
        :disabled="isInFirstPage"
        :data-cy-pagination-page="startPage"
      >
        {{ startPage }}
      </button>
    </li>

    <!-- Visible Buttons Start -->

    <li
      v-for="(page, pageIndex) in generatePageRange"
      :key="pageIndex"
      class="pagination-item"
    >
      <button
        type="button"
        @click="onChangePage(page)"
        :disabled="isPageActive(page)"
        :class="{ 'active': isPageActive(page) }"
        :data-cy-pagination-page="page"
      >
        {{ page }}
      </button>
    </li>

    <!-- Visible Buttons End -->

    <li class="pagination-item">
      <button
        type="button"
        @click="onChangePage(totalPages)"
        :disabled="isInLastPage"
        :class="{ 'active': isInLastPage }"
        :data-cy-pagination-page="totalPages"
      >
        {{ totalPages }}
      </button>
    </li>
    <li class="pagination-item">
      <button
        type="button"
        @click="onChangePage(currentPage + 1)"
        :disabled="isInLastPage"
        data-cy-pagination="up"
      >
        &gt;&gt;
      </button>
    </li>
  </ul>
</template>

<script>
export default {
  name: 'pagination',
  emits: ['pagechanged'],
  props: {
    visiblePagesInRange: {
      type: Number,
      required: true,
    },
    totalArticles: {
      type: Number,
      required: true
    },
    articlesPerPage: {
      type: Number,
      required: true
    },
    currentPage: {
      type: Number,
      required: true
    }
  },
  data () {
    return {
      startPage: 1,
      newPagePosition: 1
    }
  },
  computed: {
    showPagination () {
      return this.totalArticles > this.articlesPerPage;
    },
    totalPages () {
      const proposedTotal = (this.totalArticles - (this.totalArticles % this.articlesPerPage)) / this.articlesPerPage;
      if (this.totalArticles - (this.articlesPerPage * proposedTotal) > 0) {
        return proposedTotal + 1;
      }
      return proposedTotal;
    },
    reversePageRange () {
      return this.currentPage + this.visiblePagesInRange >= this.totalPages;
    },
    startOfRange () {
      return this.reversePageRange ? this.totalPages - this.visiblePagesInRange : this.currentPage;
    },
    generatePageRange () {
      const pageRange = [];
      const loops = this.currentPage + this.visiblePagesInRange;
      for (let i = this.startOfRange; i <= loops; i++) {
        if (i <= 0) continue;
        if (i === this.startPage) continue;
        if (i >= this.totalPages) continue;
        pageRange.push(i);
      }
      return pageRange;
    },
    isInFirstPage() {
      return this.currentPage === 1;
    },
    isInLastPage() {
      return this.currentPage === this.totalPages;
    },
    nextArticleStartPosition () {
      return this.newPagePosition === this.startPage ? 0 : (this.newPagePosition - this.startPage) * this.articlesPerPage
    }
  },
  methods: {
    onChangePage (newPageNumber) {
      this.newPagePosition = newPageNumber;
      this.$emit('pagechanged', { newPage: newPageNumber, newPosition: this.nextArticleStartPosition });
    },
    isPageActive(page) {
      return this.currentPage === page;
    }
  }
 }
</script>

<style>
.pagination {
  list-style-type: none;
  text-align: right;
  padding-top: 20px;
}

.pagination-item {
  display: inline-block;

  button {  
    color: #757f87;
    font-size: 16px;
    text-decoration: none;
    padding: 0 0 0 8px;

    &:disabled {
      pointer-events: none;
      opacity: .5;
    }

    &:hover {
      cursor: pointer;
    }
  }

  button:hover, .active {
    color: #da4d47;
    font-weight: 700;
    text-decoration: underline;
  }

  .active:disabled {
    opacity: 1;
  }
}
</style>
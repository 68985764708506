<template>
  <div>
    <component
      :is="startPage"
    />
  </div>
</template>

<script>
export default {
  name:'Root',
  props: {
    startPage: {
      type: String,
      required: true
    },
    contentTypeFilters: {
      type: Array,
      required: true
    },
    labels: {
      type: Object,
      required: true
    },
    searchApiPrefix: {
      type: String,
      required: true
    },
    queryParam: {
      type: String,
      required: true
    }
  },
  created () {
    this.$store.dispatch('initialiseStore', {
      prefix : this.searchApiPrefix,
      urlQueryStr: this.queryParam,
      contentTypeFilters: this.contentTypeFilters,
      labels: this.labels
    });
  }
}
</script>

<style lang="scss" scoped>
</style>
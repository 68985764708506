<template>
    <div>
        <div class="search-container">
            <form 
              @submit.prevent="sendSearchBarQuery"
              autocomplete="off"
              >
                <input
                  v-model.trim="searchQuery"
                  :placeholder="labels.contentTypesHint"
                  type="text"
                  id="search-term"
                />
                <button type="submit">
                    <svg width="18" height="20" viewBox="0 0 13 13">
                        <title>search</title>
                        <path
                            d="m4.8495 7.8226c0.82666 0 1.5262-0.29146 2.0985-0.87438 0.57232-0.58292 0.86378-1.2877 0.87438-2.1144 0.010599-0.82666-0.28086-1.5262-0.87438-2.0985-0.59352-0.57232-1.293-0.86378-2.0985-0.87438-0.8055-0.010599-1.5103 0.28086-2.1144 0.87438-0.60414 0.59352-0.8956 1.293-0.87438 2.0985 0.021197 0.8055 0.31266 1.5103 0.87438 2.1144 0.56172 0.60414 1.2665 0.8956 2.1144 0.87438zm4.4695 0.2115 3.681 3.6819-1.259 1.284-3.6817-3.7 0.0019784-0.69479-0.090043-0.098846c-0.87973 0.76087-1.92 1.1413-3.1207 1.1413-1.3553 0-2.5025-0.46363-3.4417-1.3909s-1.4088-2.0686-1.4088-3.4239c0-1.3553 0.4696-2.4966 1.4088-3.4239 0.9392-0.92727 2.0864-1.3969 3.4417-1.4088 1.3553-0.011889 2.4906 0.45771 3.406 1.4088 0.9154 0.95107 1.379 2.0924 1.3909 3.4239 0 1.2126-0.38043 2.2588-1.1413 3.1385l0.098834 0.090049z">
                        </path>
                    </svg>
                </button>
            </form>
        </div>

        <div
          class="articles-container"
        >
          <article-tile
            v-for="(article, articleIndex) in articles"
            :key="articleIndex"
            :articleFields="article.fields"
            :articleId="article.id"
            :isHeroArticle="false" 
            :showCouncilInDate="false" />
        </div>
        <div
          v-show="showNoArticleMsg" 
          class="no-articles"
        >
            <h2>{{ labels.errorViewLabel }}</h2>
        </div>
        <pagination
          :visiblePagesInRange="numberOfVisiblePages"
          :totalArticles="articleHits"
          :articlesPerPage="numberOfArticles"
          :currentPage="currentPage"
          @pagechanged="updateStartPositionAndPage" 
        />
    </div>
</template>

<script>
import { mapGetters } from "vuex";
import ArticleTile from "../components/article/ArticleTile";
import Pagination from "../components/core/Pagination";

export default {
    name: "Search",
    components: {
      ArticleTile,
      Pagination,
    },
    data() {
      return {
        startPosition: 0,
        numberOfArticles: 9,
        numberOfVisiblePages: 3,
        currentPage: 1,
        searchQuery: "",
        searchActive: false,
        getParamRemoved: false
      };
    },
    created() {
      if (this.hasUrlTerm) {
        this.searchQuery = this.getUrlTerm;
        this.sendQuery();
      }
    },
    computed: {
    ...mapGetters({
        articleHits: "getArticleHits",
        articles: "getArticles",
        articleStart: "getArticleStart",
        labels: 'getLabels',
        urlTerm: "getUrlQueryTerm",
        hasUrlTerm: "getUrlQueryFound",
        queryComplete: "getCloudSeachQueryComplete"
    }),
    showNoArticleMsg () {
      return this.queryComplete && this.searchActive && this.articleHits === 0;
    },
    getUrlTerm () {
      return this.urlTerm.toLowerCase();
      }
    },
    methods: {
      updateStartPositionAndPage(event) {
        const { newPage, newPosition } = event;
        this.currentPage = newPage;
        this.startPosition = newPosition;
        this.sendQuery();
      },
      sendQuery () {
        if (!this.searchActive) this.searchActive = true;
        this.$store.dispatch("performSearchQuery", {
          searchTerm: this.searchQuery,
          start: this.startPosition,
          size: this.numberOfArticles,
        });
      },
      removeGetParam () {
        window.history.pushState({}, document.title, window.location.pathname);
        this.getParamRemoved = true;
      },
      sendSearchBarQuery () {
        if (!this.searchQuery) return; // No empty query
        if (!this.getParamRemoved && this.hasUrlTerm) this.removeGetParam();
        this.startPosition = 0; // reset pagination
        this.sendQuery();
      }
    },
};
</script>

<style lang="scss" scoped>
.articles-container {
    color: #757f87;
}

.article-tile-container {
    margin-block: 1rem;
}

.no-articles {
    text-align: center;
    margin-block: 1rem;
}

.search-container {
    display: flex;
    justify-content: center;
    margin-bottom: 3rem;

    form {
        display: flex;
        flex-direction: row-reverse;
        align-items: center;
        height: 3rem;
        border: 1px solid #757f87;
        border-radius: 0.7rem;
        color: #757f87;
        width: 50%;

        @media only screen and (max-width: 767px) {
            width: 90%;
        }
    }

    button {
        display: flex;
        align-items: center;
        stroke: none;
        fill: #da4d47;
        padding-inline: 0.6rem;
        cursor: pointer;
    }

    #search-term {
        border: none;
        border-radius: 0.7rem;
        flex-grow: 1;
        height: calc(100% - 2px);

        &:focus {
            outline: none;
        }
    }
}
</style>

<template>
  <div
    :data-cy-article-id="articleId" 
    class="article-row-container"
    >
    <div class="date">{{ getDate }}</div>
    <div class="council">{{ getContentType }}</div>
    <div class="report">
      <a 
        :href="getPath"
      >{{ getTitle }}
      </a>
    </div>
  </div>
</template>

<script>
import { constantVars } from '../../../utils/constants';

export default {
  name: 'ArticleRow',
  props: {
    articleField: {
      type: Object,
      required: true
    },
    articleId: {
      type: String,
      required: true
    }
  },
  computed: {
    getDate () {
      const articleDate = new Date(this.articleField.publication_date);
      const month = articleDate.toLocaleString('en-us', { month:'short'});
      return `${articleDate.getDate()} ${month} ${articleDate.getFullYear()}`;
    },
    getContentType () {
      if (!Object.hasOwn(this.articleField, 'content_type')) return '';
      return this.articleField.content_type.replaceAll(constantVars.COUNCIL_REPLACE, '');
    },
    getTitle () {
      return this.articleField.title;
    },
    getPath () {
      return this.articleField.path;
    }
  }
}
</script>

<style lang="scss" scoped>
@font-face {
  font-family: Open Sans;
  src: url("../../../resources/fonts/OpenSans/OpenSans-Regular.woff2") format('woff2');
}

.article-row-container {
  padding: 10px 0;
  width: 100%;
  line-height: 1.5rem;

  @media only screen and (max-width:767px) {
    background: rgba(237, 237, 238, 0.7);
    margin: 0 0 15px 0;
    padding: 20px;
  }

  > div {
    display: inline-block;
    font-family: Open Sans;
    color: #3c4348;
    font-size: 16px;
    vertical-align: top;

    &.date,
    &.council {
      @media only screen and (max-width:767px) {
        margin: 0 0 10px 0;
        width: 100%;
        color: #757f87;
      }
    }
  }

  .date {
    width: 15%;
    text-align: center;

    @media only screen and (max-width:767px) {
      text-align: left;
    }
  }
  .council {
    width: 25%;
  }

  .report {
    width: 60%;

    @media only screen and (max-width:767px) {
      color: #3c4248;
      font-size: 20px;
      margin: 0 0 10px 0;
      width: 100%;
    }
  }

  .council,
  .report {
    text-align: left;
  }

  a {
    color: #3c4348;
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }
}
</style>
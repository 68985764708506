<template>
  <div>
    <div class="articles-container">
      <article-tile
        v-for="(article, articleIndex) in articles"
        :key="articleIndex"
        :articleFields="article.fields"
        :articleId="article.id"
        :isHeroArticle="false"
        :showCouncilInDate="true"
      />
    </div>
    <pagination 
      :visiblePagesInRange="numberOfVisiblePages"
      :totalArticles="articleHits"
      :articlesPerPage="numberOfArticles"
      :currentPage="currentPage"
      @pagechanged="updateStartPositionAndPage"
    />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import ArticleTile from '../components/article/ArticleTile'
import Pagination from '../components/core/Pagination';

export default {
  name: 'Council',
  components: {
    ArticleTile,
    Pagination
  },
  data ()  {
    return {
      startPosition: 0,
      numberOfArticles: 8,
      numberOfVisiblePages: 3,
      currentPage: 1
    }
  },
  created () {
    this.sendQuery();
  },
  computed: {
    ...mapGetters({
      articleHits: 'getArticleHits',
      articles: 'getArticles',
      articleStart: 'getArticleStart',
      contentTypeFilter: 'getFirstContentTypeFilter'
    })
  },
  methods: {
    updateStartPositionAndPage (event) {
      const { newPage, newPosition } = event;
      this.currentPage = newPage;
      this.startPosition = newPosition;
      this.sendQuery();
    },
    sendQuery () {
      this.$store.dispatch('performQuery', {
      useContentType: true,
      searchTermsArr: [this.contentTypeFilter],
      start: this.startPosition,
      size: this.numberOfArticles,
      secondaryQuery: false
      });
    }
  }
}
</script>

<style lang="scss" scoped>
.articles-container {
  display: flex;
  flex-wrap: wrap;
  padding-bottom: 40px;
  justify-content: space-between;
  
    &:deep {    
      .article-tile-container {
        margin: 0 0 20px 0;
        flex: 0 0 100%;
        @media screen and (min-width: 768px) {
          flex: 0 0 calc((100% / 2) - 10px);
        }
      }
    }
}
</style>
<template>
  <div class="article-type-container">
    <button
      @click="updateArticletype"
      :class="{'button-active': isButtonActive }"
      :data-cy-article-type="articleType"
      class="article-type-button"
    >
      {{ articleTypeName }}
    </button>
  </div>
</template>

<script>
export default {
  name: 'ArticleType',
  emits: ['typeupdate'],
  props: {
    articleType: {
      type: String,
      required: true
    },
    articleTypeName: {
      type: String,
      required: true
    },
    activeType: {
      type: String,
      required: true
    }
  },
  computed: {
    isButtonActive () {
      return this.articleType === this.activeType;
    }
  },
  methods: {
    updateArticletype () {
      if (this.isButtonActive) return;
      this.$emit('typeupdate', this.articleType)
    }
  }
}
</script>

<style lang="scss" scoped>

@font-face {
  font-family: Open Sans Semi Bold;
  src: url("../../../resources/fonts/OpenSans/OpenSans-SemiBold.woff2") format("woff2");
}

.article-type-container {
  @media only screen and (max-width:767px) {
    margin: 0 0 15px 0;
  }
}

.article-type-button {
  font-family: Open Sans Semi Bold;
  font-weight: 600;
  text-transform: uppercase;
  color: #3c4348;
  border-radius: 5px;
  background-color: transparent;
  border: 1px solid #bebebe;
  padding: 10px 20px;
  cursor: pointer;
  margin-right: 20px;
  font-size: 16px;
}

.button-active {
  color: #da4d47;
}
</style>
<template>
  <div
    :class="{'hero-article': isHeroArticle}"
    :data-cy-article-id="articleId"
    class="article-tile-container"
  >
    <a
      :href="getPath"
      class="title-link"
    >
      <div class="article-tile-content">
        <h3 class="article-title">{{ getTitle }}</h3>
        <article-date 
          :publicationDate="getPublicationDate"
          :contentType="getContentType"
          :showCouncil="showCouncilInDate"
        />
        <div class="description">{{ getDescription }}</div>
        <div class="article-read-more">READ MORE &gt;&gt;</div>
     </div>
    </a>
  </div>
</template>

<script>
import ArticleDate from './ArticleDate';

export default {
  name: 'ArticleTile',
  components: {
    ArticleDate
  },
  props: {
    articleFields: {
      type: Object,
      required: true
    },
    articleId: {
      type: String,
      required: true
    },
    isHeroArticle: {
      type: Boolean,
      required: true
    },
    showCouncilInDate: {
      type: Boolean,
      required: true
    }
  },
  computed: {
    getTitle () {
      return this.articleFields.title;
    },
    getDescription () {
      return this.articleFields.description;
    },
    getContentType () {
      return this.articleFields.content_type;
    },
    getPublicationDate () {
      return this.articleFields.publication_date;
    },
    getPath () {
      return this.articleFields.path;
    }
  }
}
</script>

<style lang="scss" scoped>

@font-face {
  font-family: Open Sans Semi Bold ;
  src: url("../../../resources/fonts/OpenSans/OpenSans-SemiBold.woff2") format("woff2");
}

@font-face {
  font-family: Open Sans;
  src: url("../../../resources/fonts/OpenSans/OpenSans-Regular.woff2") format('woff2');
}

@font-face {
  font-family: Open Sans Bold;
  src: url("../../../resources/fonts/OpenSans/OpenSans-Bold.woff2") format('woff2');
}

.article-tile-content {
  background: rgba(237,237,238, 0.7);
  color: #757f87;
  padding: 25px 35px;
  height: 100%;
}

.title-link {
  text-decoration: none;
}
.article-title {
  min-height: 55px;
  color: #3c4248;
  font-size: 20px;
  font-family: Open Sans Semi Bold;
  font-weight: 600;
  margin: 0 0 10px 0;
}
.description {
  font-size: 15px;
  font-family: Open Sans;
  line-height: initial;
}
.article-read-more {
  color: #757f87;
  display: block;
  font-weight: 700;
  margin-top: 10px;
  text-transform: uppercase;
  font-family: Open Sans Bold;
}
.hero-article {
  .article-tile-content {
    background: rgba(193, 198, 201, 0.7);
    margin-bottom: 20px;
  }
}
.article-tile-content:hover {
  background: #f08b21;
  transition: 0.3s ease;
  .article-title,
  .description {
    color: white;
    transition: 0.3s ease;
  }
  .article-read-more {
    color: #3c4248;
    transition: 0.3s ease;
  }
  &:deep {
    .article-date-container {
      color: white;
      transition: 0.3s ease;
    }
  }
}

</style>
<template>
  <div class="article-selector-container">
    <button 
      @click="updateSelector"
      :data-cy-selector="contentType"
      :class="{'button-active': isActive }"
      class="article-selector-button"
    >
      {{ articleSelectorName }}
    </button>
  </div>
</template>

<script>
export default {
  name: 'ArticleSelector',
  emits: ['selectorupdate'],
  props: {
    articleSelectorName: {
      type: String,
      required: true
    },
    contentType: {
      type: String,
      required: true
    },
    activeSelector: {
      type: String,
      required: true
    },
    useContentType: {
      type: Boolean,
      required: true
    }
  },
  computed: {
    isActive () {
      return this.articleSelectorName === this.activeSelector;
    }
  },
  methods: {
    updateSelector () {
      if (this.isActive) return;
      this.$emit('selectorupdate', { 
        articleSelectorName: this.articleSelectorName,
        contentType: this.contentType,
        useContentType: this.useContentType
      })
    }
  }
}
</script>

<style lang="scss" scoped>

@font-face {
  font-family: Open Sans Semi Bold;
  src: url("../../../resources/fonts/OpenSans/OpenSans-SemiBold.woff2") format("woff2");
}

.article-selector-button {
  font-family: Open Sans Semi Bold;
  font-weight: 600;
  font-size: 16px;
  color: #3c4348;
  border-radius: 5px;
  background-color: transparent;
  border: 1px solid transparent;
  padding: 10px;
  margin: 0 30px;
  text-transform: uppercase;
  cursor: pointer;

  &:first-child {
    margin-left: 0;
    padding-left: 0;
  }

  @media only screen and (max-width:767px) {
    margin: 0 30px 10px 0;
    padding: 0 10px;
  }
}

.button-active {
  color: #da4d47;
}
</style>
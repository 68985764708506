import { createApp } from "vue";
import { constantVars, vuePages } from "../utils/constants";
import globalStore from "./store/globalStore";

import Root from './Root.vue';
import Blog from './pages/Blog.vue';
import Council from './pages/Council.vue';
import Featured from './pages/Featured.vue';
import News from './pages/News.vue';
import Reports from './pages/Reports.vue';
import Search from './pages/Search.vue';

const hasVueMountPoint = () => {
  return !!document.querySelector(constantVars.VUE_MOUNT_POINT);
}

const getMountPoint = () => {
  return document.querySelector(constantVars.VUE_MOUNT_POINT);
}

const getDataParams = () => {
  const mountPoint = getMountPoint();
  return JSON.parse(mountPoint.dataset.params);
}

const getAllContentTypeFilters = () => {
  const dataParams = getDataParams();
  let contentFilters = [];
  for (const contentFilter of dataParams.contentTypeFilters) {
    contentFilters.push(contentFilter.contentType);
  }
  return contentFilters;
}

const getFirstContentTypeFilter = () => {
  const contentFilters = getAllContentTypeFilters();
  return contentFilters[0];
}

const getRequestedPage = () => {
  const contentTypeFilter = getFirstContentTypeFilter()
  const keyword = contentTypeFilter.split(' ');
  return keyword[0];
}

const getVuePageMatch = () => {
  const requestedPage = getRequestedPage();
  for (const vuePage in vuePages) {
    if (vuePages[vuePage] === requestedPage) return vuePage;
  }
  return false;
}

const getSearchApiPrefix = () => {
  const dataParams = getDataParams();
  return dataParams.searchApiPrefix;
}

const getLabels = () => {
  const dataParams = getDataParams();
  return dataParams.labels;
}

const getQueryParam = () => {
  const params = new URLSearchParams(window.location.search);
  return params.get(constantVars.QUERY_PARAM) ? params.get(constantVars.QUERY_PARAM) : '';
}

const initVue = () => {
  if (!hasVueMountPoint()) return;

  const contentTypeFilters = getAllContentTypeFilters();
  const vuePageMatch = getVuePageMatch();
  const searchApiPrefix = getSearchApiPrefix();
  const queryParam = getQueryParam();
  const labels = getLabels();

  if (!vuePageMatch) return;
  const startPage = vuePages[vuePageMatch]

  const app = createApp(Root, {
     startPage,
     contentTypeFilters,
     labels,
     searchApiPrefix,
     queryParam
     });

  app.component(vuePages.BLOG, Blog);
  app.component(vuePages.COUNCIL, Council);
  app.component(vuePages.FEATURED, Featured);
  app.component(vuePages.NEWS, News);
  app.component(vuePages.REPORTS, Reports);
  app.component(vuePages.SEARCH, Search);

  app.config.devtools = true;
  app.use(globalStore);
  app.mount(constantVars.VUE_MOUNT_POINT);
}

initVue();

import crypto from 'crypto';

const createHashKey = (queryStr) => {
  const hash = crypto.createHash('sha256').update(queryStr).digest('hex');
  return hash.slice(0,12);
}

const queryStore = {
  namespaced: true,
  state: {
    queries: [],
    maxQueries: 10
  },
  mutations: {
    addQuery: (state, payload) => {
      const { key, data } = payload;
      state.queries.push({ key, data });
    },
    removeFirstQuery: (state) => {
      state.queries.shift();
    }
  },
  getters: {
    getQuery: (state) => (queryKey) => {
      const checkForKey = state.queries.filter(query => query.key === createHashKey(queryKey));
      return checkForKey.length >= 1 ? checkForKey[0] : false;
    },
    isQueriesFull: (state) => {
      return state.queries.length >= state.maxQueries;
    }
  },
  actions: {
    cacheQuery: ({ commit, getters }, { query, responseData }) => {
      if (getters.isQueriesFull) commit('removeFirstQuery');
      commit('addQuery', { key: createHashKey(query), data: responseData });
    }
  }
}

export default queryStore;
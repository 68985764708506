<template>
  <div class="reports-page-conatiner" data-cy-page="reports">
    <div class="article-types">
      <article-type
        articleType="all"
        articleTypeName="view all"
        :activeType="currentArticleType"
        @typeupdate="updateArticleType"
      />
      <article-type
        articleType="category"
        articleTypeName="by category"
        :activeType="currentArticleType"
        @typeupdate="updateArticleType"
      />
      <article-type
        articleType="council"
        articleTypeName="by council"
        :activeType="currentArticleType"
        @typeupdate="updateArticleType"
      />
    </div>
    <div class="article-selectors">
      <div class="category-selectors">
        <article-selector
          v-for="(category, categoryIndex) in categories"
          v-show="showCategories"
          :key="categoryIndex"
          :articleSelectorName="category"
          :contentType="category"
          :activeSelector="currentArticleSelector"
          :useContentType="false"
          @selectorupdate="updateSelectorType"
        />
      </div>
      <div class="council-selectors">
        <article-selector
          v-for="(council, councilIndex) in councils"
          v-show="showCouncils"
          :key="councilIndex"
          :articleSelectorName="council"
          :contentType="getContentType(council)"
          :activeSelector="currentArticleSelector"
          :useContentType="true"
          @selectorupdate="updateSelectorType"
        />
      </div>
    </div>
    <div class="all-reports-info">
      All reports: {{ currentArticleSelector }}
    </div>
    <div class="all-reports-container">
      <div class="reports-title">
        <div class="date">DATE</div>
        <div class="council">COUNCIL</div>
        <div class="report">REPORT</div>
      </div>
        <article-row
          v-for="(article, articleIndex) in articles"
          :key="articleIndex"
          :articleField="article.fields"
          :articleId="article.id"
        />
    </div>
    <div class="reports-pagination">
      <pagination 
        :visiblePagesInRange="numberOfVisiblePages"
        :totalArticles="articleHits"
        :articlesPerPage="numberOfArticles"
        :currentPage="currentPage"
        @pagechanged="updateStartPositionAndPage"
      />
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { categories, councils, constantVars } from '../../utils/constants';
import ArticleType from '../components/article/ArticleType';
import ArticleSelector from '../components/article/ArticleSelector';
import ArticleRow from '../components/article/ArticleRow';
import Pagination from '../components/core/Pagination';

export default {
  name: 'Reports',
  components: {
    ArticleType,
    ArticleSelector,
    ArticleRow,
    Pagination
  },
  data () {
    return {
      categories: [],
      councils: [],
      baseStartPosition: 0,
      startPosition: 0,
      numberOfArticles: 10,
      numberOfVisiblePages: 3,
      baseCurrentPage: 1,
      currentPage: 1,
      currentArticleType: 'all',
      currentArticleSelector: '',
      useContentType: true,
      cuurentTypeFilter: '',
      otherQuerySent: false
    }
  },
  created () {
    this.councils = councils;
    this.categories = categories;

    if (this.hasQueryTerm && this.matchQueryTerm) {
      this.currentArticleType = 'category';
      const params = { 
        articleSelectorName: this.matchQueryTerm,
        contentType: this.matchQueryTerm,
        useContentType: false,
      }
      this.updateSelectorType(params);
      return;
    }

    this.cuurentTypeFilter = constantVars.COUNCIL_ALL_SEARCH;
    this.sendQuery();
  },
  computed: {
    ...mapGetters({
      articleHits: 'getArticleHits',
      articles: 'getArticles',
      articleStart: 'getArticleStart',
      hasQueryTerm: 'getUrlQueryFound',
      queryTerm: 'getUrlQueryTerm'
    }),
    showCategories () {
      return this.currentArticleType === 'category';
    },
    showCouncils () {
      return this.currentArticleType === 'council';
    },
    matchQueryTerm () {
     const matchIndex = this.categories.indexOf(this.queryTerm);
     if (matchIndex > -1) return this.categories[matchIndex];
     return false;
    },
  },
  methods: {
    updateArticleType (event) {
      this.currentArticleType = event;
      if (this.currentArticleType === 'all' && this.otherQuerySent) {
        this.startPosition = this.baseStartPosition;
        this.currentPage = this.baseCurrentPage;
        this.useContentType = true;
        this.cuurentTypeFilter = constantVars.COUNCIL_ALL_SEARCH;
        this.currentArticleSelector = '';
        this.otherQuerySent = false;
        this.sendQuery();
      }
    },
    getContentType (council) {
      return ''.concat(constantVars.COUNCIL_REPLACE, council);
    },
    updateSelectorType (event) {
      const { articleSelectorName, contentType, useContentType} = event;
      this.startPosition = this.baseStartPosition;
      this.currentPage = this.baseCurrentPage;
      this.currentArticleSelector = articleSelectorName;
      this.cuurentTypeFilter = contentType;
      this.useContentType = useContentType;
      this.otherQuerySent = true;
      this.sendQuery();
    },
    updateStartPositionAndPage (event) {
      const { newPage, newPosition } = event;
      this.currentPage = newPage;
      this.startPosition = newPosition;
      this.sendQuery();
    },
    sendQuery () {
      this.$store.dispatch('performQuery', {
      useContentType: this.useContentType,
      searchTermsArr: [this.cuurentTypeFilter],
      start: this.startPosition,
      size: this.numberOfArticles,
      secondaryQuery: false
      });
    },
  }
}
</script>

<style lang="scss" scoped>

@font-face {
  font-family: Ropa Sans;
  src: url("../../resources/fonts/RopaSans/RopaSans-Regular.woff2") format('woff2');
}

@font-face {
  font-family: Ropa Sans Bold;
  src: url("../../resources/fonts/RopaSans/RopaSans-Regular.woff2") format('woff2');
}

.reports-page-conatiner {
  @media (min-width: 767px) and (max-width: 1200px) {
    padding-inline: 20px;
  }
}

.category-selectors,
.article-types,
.council-selectors {
  display: flex;
  flex-wrap: wrap;
  padding: 0 0 20px 0;
}

.category-selectors {
  padding-bottom: 0;
}

.all-reports-info {
  color: #3c4348;
  font-family: Ropa Sans;
  font-size: 19px;
  font-weight: 600;
  padding: 20px 0 15px 0;
}

.all-reports-container {
  display: table;
  width: 100%;
  border-collapse: collapse;
}

.reports-title {
  border-bottom: 2px solid #d6d8d9;
  border-top: 2px solid #d6d8d9;
  color: #3c4348;
  font-family: Ropa Sans Bold;
  font-size: 23px;
  font-weight: 700;
  text-transform: uppercase;
  padding: 10px 0;

  @media only screen and (max-width: 767px) {
    display: none;
  }

  > div {
    display: inline-block;
  }

  .date {
    text-align: center;
    width: 15%;
  }

  .council {
    width: 25%;
  }

  .report {
    width: 60%;
  }

  .council,
  .report {
    text-align: left;
  }
}
</style>
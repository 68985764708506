import { createStore } from 'vuex';
import { constantVars } from '../../utils/constants';
import queryStore from './queryStore';

const getCloudSeachSite = () => {
  if (!window.tccc?.cloudsearch) return constantVars.CLOUDSEARCH_SITE;
  if (!Object.hasOwn(window.tccc, 'cloudsearch')) return constantVars.CLOUDSEARCH_SITE;
  return window.tccc.cloudsearch;
}

const hasCloudSearchError = (responseData) => {
  return Object.hasOwn(responseData, 'error') &&
  Object.hasOwn(responseData, 'message') &&
  !Object.hasOwn(responseData, 'status');
}

const getCloudSearchError = (responseData) => {
  return responseData.message;
}

const addEncodedQuotes = (paramStr) => {
  const encodedQuote = '%27';
  return encodedQuote.concat(paramStr, encodedQuote);
}

const encodeTerms = (paramStr) => {
  return encodeURIComponent(paramStr);
}

const getSearchUrl = () => {
  return constantVars.SEARCH_URL;
}

const getSearchType = (useContentType, searchTerm) => {
  const CONTENT_TYPE = '+content_type:+';
  const KEYWORDS = '+keywords:+'
  if (searchTerm === constantVars.FEATURED_SEARCH_TERM) return KEYWORDS;
  return useContentType ? CONTENT_TYPE : KEYWORDS;
}

const addQueryParam = (useContentType, searchTermsArr) => {
  const start = '?q=(and'
  const end = ')';
  let searchStr = '';
  for (const searchTerm of searchTermsArr) {
    searchStr = searchStr.concat(
      getSearchType(useContentType, searchTerm),
      addEncodedQuotes(encodeTerms(searchTerm))
      );
  }
  return start.concat(searchStr, end);
}

const addSiteParam = () => {
  const siteParam = '&fq=site:';
  const site = addEncodedQuotes(getCloudSeachSite());
  return siteParam.concat(site);
}

const addSizeParam = (sizeNumber) => {
  const sizeParam = '&size=';
  return sizeParam.concat(String(sizeNumber));
}

const addStartParam = (startNumber) => {
  const startParam = '&start=';
  return startParam.concat(String(startNumber));
}

const addExtraParams = () => {
  return '&q.parser=structured&sort=publication_date+desc';
}

const generateQueryUrl = (prefix, useContentType, searchTermsArr, start, size) => {
  return prefix.concat(
    getSearchUrl(),
    addQueryParam(useContentType, searchTermsArr),
    addExtraParams(),
    addSiteParam(),
    addStartParam(start),
    addSizeParam(size)
  );
}

const createSearchParam = (searchTerm) => {
  const start = '?q=';
  return start.concat(encodeURIComponent(searchTerm))
}

const generateSearchQueryURL = (prefix, searchParam, start, size) => {
  return prefix.concat(
    getSearchUrl(),
    searchParam,
    addSiteParam(),
    addStartParam(start),
    addSizeParam(size)
  )
}

const globalStore = createStore({
  modules: {
    query: queryStore
  },
  state: {
    apiError: false,
    apiErrorMessage: '',
    searchApiPrefix: '',
    articleHits: 0,
    articles: [],
    secondaryArticles: [],
    articleStart: 0,
    contentTypeFilters: [],
    labels: {},
    urlQueryTerm: '',
    urlQueryFound: false,
    cloudSeachQueryComplete: false
  },
  mutations: {
    setApiError: (state, status) => {
      state.apiError = status;
    },
    setApiErrorMessage: (state, message) => {
      state.apiErrorMessage = message;
    },
    setSearchApiPrefix: (state, prefix) => {
      state.searchApiPrefix = prefix
    },
    setArticleHits: (state, responseData) => {
      state.articleHits = responseData.hits.found;
    },
    setArticles: (state, responseData) => {
      state.articles = responseData.hits.hit;
    },
    setSecondaryArticles: (state, responseData) => {
      state.secondaryArticles = responseData.hits.hit;
    },
    setArticleStart: (state, responseData) => {
      state.articleStart = responseData.hits.start;
    },
    setContentTypeFilters: (state, contentTypeFilters) => {
      state.contentTypeFilters = contentTypeFilters;
    },
    setLabels: (state, labels) => {
      state.labels = Object.assign({}, state.labels, labels);
    },
    setUrlQueryTerm: (state, urlQueryStr) => {
      const firstLetter = urlQueryStr.charAt(0).toUpperCase();
      state.urlQueryTerm = firstLetter.concat(urlQueryStr.slice(1));
    },
    setUrlQueryFound: (state, status) => {
      state.urlQueryFound = status;
    },
    setCloudSeachQueryComplete: (state, status) => {
      state.cloudSeachQueryComplete = status;
    }
  },
  getters: {
    getApiError: state => state.apiError,
    getSearchApiPrefix: state => state.searchApiPrefix,
    getArticleHits: state => state.articleHits,
    getArticles: state => state.articles,
    getSecondaryArticles: state => state.secondaryArticles,
    getArticleStart: state => state.articleStart,
    getContentTypeFilters: state => state.contentTypeFilters,
    getLabels: state => state.labels,
    getFirstContentTypeFilter: state => state.contentTypeFilters[0],
    getUrlQueryTerm: state => state.urlQueryTerm,
    getUrlQueryFound: state => state.urlQueryFound,
    getCloudSeachQueryComplete: state => state.cloudSeachQueryComplete
  },
  actions: {
    performSearchQuery: ({commit, getters, dispatch}, {searchTerm, start, size}) => {
      const query = generateSearchQueryURL(getters['getSearchApiPrefix'], createSearchParam(searchTerm), start, size);
      const cachedQueryCheck = getters['query/getQuery'](query);
      if (cachedQueryCheck) {
        commit('setArticleHits', cachedQueryCheck.data);
        commit('setArticles', cachedQueryCheck.data);
        commit('setArticleStart', cachedQueryCheck.data);
        return;
      }

      commit('setCloudSeachQueryComplete', false);
      fetch(query)
      .then((response) => response.json())
      .then((responseData) => {
        if (hasCloudSearchError(responseData)) {
          throw new Error(getCloudSearchError(responseData));
        }
        dispatch('query/cacheQuery', { query, responseData });
        commit('setArticleHits', responseData);
        commit('setArticles', responseData);
        commit('setArticleStart', responseData);
        commit('setCloudSeachQueryComplete', true);
      })
      .catch((error) => {
        commit('setApiError', true);
        commit('setApiErrorMessage', error.message);
      })
    },
    performQuery: ({ commit, getters, dispatch }, { useContentType, searchTermsArr, start, size, secondaryQuery }) => {
      const query = generateQueryUrl(getters['getSearchApiPrefix'], useContentType, searchTermsArr, start, size);
      const cachedQueryCheck = getters['query/getQuery'](query);
      if (cachedQueryCheck) {
        commit('setArticleHits', cachedQueryCheck.data);
        commit('setArticles', cachedQueryCheck.data);
        commit('setArticleStart', cachedQueryCheck.data);
        return;
      }

      commit('setCloudSeachQueryComplete', false);
      fetch(query)
        .then((response) => response.json())
        .then((responseData) => {
          if (hasCloudSearchError(responseData)) {
            throw new Error(getCloudSearchError(responseData));
          }
          dispatch('query/cacheQuery', { query, responseData });
          if (!secondaryQuery) {
            commit('setArticleHits', responseData);
            commit('setArticles', responseData);
            commit('setArticleStart', responseData);
            commit('setCloudSeachQueryComplete', true);
          }
          if (secondaryQuery) {
            commit('setSecondaryArticles', responseData);
          }
        })
        .catch((error) => {
          commit('setApiError', true);
          commit('setApiErrorMessage', error.message);
        })
    },
    updateSearchApiPrefix: ({ commit }, { prefix }) => {
      commit('setSearchApiPrefix', prefix);
    },
    updateUrlQueryTerm: ({ commit }, { urlQueryStr }) => {
      if (urlQueryStr && urlQueryStr.length) {
        commit('setUrlQueryFound', true);
        commit('setUrlQueryTerm', urlQueryStr);
      }
    },
    updateContentTypeFilters: ({ commit }, { contentTypeFilters }) => {
      commit('setContentTypeFilters', contentTypeFilters);
    },
    updateLabels: ({ commit }, { labels }) => {
      commit('setLabels', labels);
    },
    initialiseStore: ({ dispatch }, { prefix, urlQueryStr, contentTypeFilters, labels }) => {
      dispatch('updateSearchApiPrefix', { prefix });
      dispatch('updateUrlQueryTerm', { urlQueryStr });
      dispatch('updateContentTypeFilters', { contentTypeFilters });
      dispatch('updateLabels', { labels } );
    }
  }
});

export default globalStore;

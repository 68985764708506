<template>
  <div class="featured-page-container">
    <div class="primary-articles-container">
      <article-tile
        v-for="(article, articleIndex) in primaryArticles"
        :key="articleIndex"
        :articleFields="article.fields"
        :articleId="article.id"
        :isHeroArticle="isHeroArticle(articleIndex)"
        :showCouncilInDate="true"
      />
    </div>
    <div 
      v-if="isSecondaryFilter"
      class="secondary-articles-container"
    >
      <div class="secondary-title">
        <h2>{{ getSecondaryFilter }}</h2>
      </div>
      <div class="secondary-articles">
        <article-tile
          v-for="(article, articleIndex) in secondaryArticles"
          :key="articleIndex"
          :articleFields="article.fields"
          :articleId="article.id"
          :isHeroArticle="false"
          :showCouncilInDate="false"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { constantVars } from '../../utils/constants';
import ArticleTile from '../components/article/ArticleTile';

export default {
  name: 'Featured',
  components: {
    ArticleTile
  },
  data()  {
    return {
      startPosition: 0,
      numberOfPrimaryArticles: 5,
      numberofSecondaryArticles: 4,
      requiredContentFilters: 3
    }
  },
  created () {
    this.sendQuery(false);
    this.sendQuery(true);
  },
  computed: {
    ...mapGetters({
      contentTypeFilters: 'getContentTypeFilters',
      primaryArticles: 'getArticles',
      secondaryArticles: 'getSecondaryArticles'
    }),
    isSecondaryFilter () {
      return this.contentTypeFilters.length >= this.requiredContentFilters;
    },
    replaceReportsFilter () {
      let filters = [...this.contentTypeFilters];
      for (const [filterIndex, filter] of filters.entries()) {
        if (filter === constantVars.FEATURED_REPORTS) {
          filters[filterIndex] = constantVars.COUNCIL_ALL_SEARCH;
        }
      }
      return filters;
    },
    primaryContentFilters () {
      let filters = [...this.replaceReportsFilter];
      if (this.isSecondaryFilter) filters.pop();
      return filters;
    },
    secondaryContentFilters () {
      let filters = [...this.replaceReportsFilter];
      if (this.isSecondaryFilter) filters.splice(1, 1);
      return filters;
    },
    getSecondaryFilter () {
      if (this.isSecondaryFilter) return this.contentTypeFilters[2];
      return '';
    }
  },
  methods: {
    sendQuery (isSecondaryQuery) {
      this.$store.dispatch('performQuery', {
      useContentType: true,
      searchTermsArr: isSecondaryQuery ? this.secondaryContentFilters : this.primaryContentFilters,
      start: this.startPosition,
      size: isSecondaryQuery ? this.numberofSecondaryArticles : this.numberOfPrimaryArticles,
      secondaryQuery: isSecondaryQuery
      });
    },
    isHeroArticle (articleIndex) {
      return !articleIndex;
    }
  }
}
</script>

<style lang="scss" scoped>

.featured-page-container {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items:center;
}

.primary-articles-container,.secondary-articles {
  display: flex;
  flex-wrap: wrap;
  padding-bottom: 40px;
  justify-content: space-between;
  
  
    &:deep{    
      .article-tile-container {
        margin: 0 0 20px 0;
        flex: 0 0 100%;
        @media screen and (min-width: 768px) {
          flex: 0 0 calc((100% / 2) - 10px);
        }
      }
      .hero-article {
        flex: 0 0 100%;
      }
    }
}
.secondary-title {

  > h2 {
    margin-bottom: 25px;
    color: #da4d47;
    font-family: Ropa Sans ;
    font-size: 28px;
    font-weight: 400;
    text-align: center;
    text-transform: uppercase;
  }
}
.secondary-articles-container {
  padding-bottom: 40px;
  .secondary-articles {

    &:deep {

      .article-title,
      .description,
      .article-read-more,
      .article-date-container {
        color: white;
      }
      .article-tile-content {
        min-height: 180px;
        padding: 30px 20px 30px 20px;
        background: #768188;
      }
      .article-title {
        min-height: 30px
      }
      .article-tile-content:hover {
        background: #da4d46;
        transition: 0.3s ease;
        .article-read-more {
          color: #3c4248;
          transition: 0.3s ease;
        }
      }
    }
  }
}

</style>